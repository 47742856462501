<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" class="firstSection" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 8">
        <h3 v-bind:class="mobile ? 'titleFirstSectionMobile' : 'titleFirstSection'" v-html="$t('titleFirstSection')"></h3>
        <div class="firstSectionLeft" v-if="!mobile">
          <h3 class="subTitleFirstSection" v-html="$t('subTitleFirstSection')"></h3>
          <p class="textFirstSection" v-html="$t('textFirstSection')"></p>
        </div>
        <v-row justify="center" v-if="mobile" style="font-size: 14px;" no-gutters>
          <v-col cols="12">
            <h3 class="subTitleFirstSectionMobile" v-html="$t('subTitleFirstSection')"></h3>
          </v-col>
          <v-col cols="8">
            <p class="textFirstSectionMobile" v-html="$t('textFirstSection')"></p>
          </v-col>
          <v-col cols="4">
            <v-img
                class="imgFirstSectionMobile"
                :src="require('@/assets/picto/pictoDiagnosis.svg')"
                contain
                height="150"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" v-if="!mobile">
        <v-img
            class="imgFirstSection"
            :src="require('@/assets/picto/pictoDiagnosis.svg')"
            contain
            height="450"
        />
      </v-col>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'secondSectionMobile' : 'secondSection'" no-gutters>
      <v-col v-bind:cols="mobile? 10 : 5" class="secondSectionLeft">
        <h4 class="subTitleSecondSection" v-html="$t('subTitleSecondSectionLeft')"></h4>
        <p class="textSecondSectionLeft" v-html="$t('textSecondSectionLeft')"></p>
        <h4 class="subTitleSecondSection" v-html="$t('subTitleSecondSectionRight')"></h4>
        <p class="textSecondSectionLeft" v-html="$t('textSecondSectionRight')"></p>
      </v-col>
      <v-col cols="1" v-if="!mobile"></v-col>
      <v-col v-bind:cols="mobile? 10 : 5" class="secondSectionRight">
        <h4 class="subTitleSecondSection" v-html="$t('subTitleThird')"></h4>
        <p class="textSecondSectionRight" v-html="$t('textThird')"></p>
      </v-col>
      <v-col cols="1" v-if="!mobile"></v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'ConferenceDiagnosisComp',
    props: ['mobile'],
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "Conference and diagnosis",
    "titleFirstSection": "Awareness and diagnosis (duration: 2h to 3h)",
    "subTitleFirstSection": "This intervention is broken down into three phases: <br> <br>",
    "textFirstSection": "1 - a participatory conference, <br> 2 - a diagnosis of the risks of musculoskeletal disorders of the participants <br> 3 - an implementation.",
    "subTitleSecondSectionLeft": "1 - The conference covers the following concepts: <br> <br>",
    "textSecondSectionLeft": "- Current recommendations in terms of physical activity practice <br> - The benefits of regular practice on physical, mental and social health <br> - Musculoskeletal disorders and their negative impacts <br> - Prevention of musculoskeletal disorders caused by physical activity",
    "subTitleSecondSectionRight": "2 - Diagnosis of the risks of musculoskeletal disorders of the participants: <br> <br>",
    "textSecondSectionRight": "During this phase, a questionnaire is distributed to all participants which they will be able to complete with the help of the facilitator. This questionnaire sheds light on work habits and postures that can lead to the appearance of musculoskeletal disorders. <br> Depending on the needs of the business, we can also perform rigorous statistical processing to highlight redundant risky habits and postures within the business.",
    "subTitleThird": "3 - Practice <br> <br>",
    "textThird": "Following these discussions, a practice presenting simple and effective exercises to prevent the onset of musculoskeletal disorders is carried out. <br> Depending on the number of people present and the space available, this practice can be carried out with all the participants or with a group of volunteers. The goal is to show simple and effective exercises that can be performed in our workplace or at home. <br> « This intervention makes it possible to become aware of the risks of MSDs at work as well as the benefits of physical activity in general, but it becomes all the more interesting as it is interested in the daily life of the employee and his own. risks while giving it suitable tools to protect itself »"
  },
  "fr": {
    "mainTitle": "Conférence et diagnostic",
    "titleFirstSection": "Sensibilisation et diagnostic (durée : 2h à 3h)",
    "subTitleFirstSection": "Cette intervention se décompose en trois phases : <br><br>",
    "textFirstSection": "1 - une conférence participative, <br> 2 - un diagnostic des risques de troubles musculo-squelettiques des participants <br> 3 - une mise en pratique.",
    "subTitleSecondSectionLeft": "1 - La conférence aborde les notions suivantes : <br><br>",
    "textSecondSectionLeft": "- Les recommandations actuelles en termes de pratique d’activité physique <br> - Les bienfaits d’une pratique régulière sur la santé physique, mentale et sociale <br> - Les troubles musculo squelettiques et leurs impacts négatifs <br> - La prévention des troubles musculo squelettiques par l’activité physique",
    "subTitleSecondSectionRight": "2 - Diagnostic des risques de troubles musculo-squelettiques des participants : <br><br>",
    "textSecondSectionRight": "Lors de cette phase, un questionnaire est distribué à l’ensemble des participants qu’ils vont pouvoir remplir avec l’aide de l’intervenant. Ce questionnaire permet de mettre en lumière des habitudes et posture de travail pouvant entrainer l’apparition de troubles musculo squelettiques. <br> En fonction des besoins de l’entreprise, nous pouvons également réaliser un traitement statistique rigoureux permettant de mettre en lumière les habitudes et postures à risque redondantes au sein de l’entreprise.",
    "subTitleThird": "3 - Mise en pratique <br> <br>",
    "textThird": "A la suite de ces échanges, une mise en pratique présentant des exercices simples et efficaces pour prévenir l’apparition des troubles musculo squelettiques est effectuée. <br> En fonction du nombre de personnes présentes et de l’espace disponible, cette mise en pratique peut-être réalisée avec l’ensemble des participants ou avec un groupe de volontaire. Le but étant de montrer des exercices simples et efficaces que l’on peut réaliser sur notre lieu de travail où à la maison. <br> « Cette intervention permet de prendre conscience des risques des TMS au travail ainsi que des bienfaits de l’activité physique en général, mais elle devient d’autant plus intéressante qu’elle s’intéresse au quotidien du salarié et à ses propres risques tout en lui donnant des outils adaptés pour se prémunir »"
  }
}
</i18n>

<style scoped>

.titleFirstSection {
  padding-right: 85px;
  text-align: right;
  color: #1579A7;
  font-weight: normal;
}

.titleFirstSectionMobile {
  margin-top: -40px;
  font-size: 16px;
  text-align: center;
  color: #1579A7;
  font-weight: normal;
}

.subTitleFirstSectionMobile {
  margin-top: 60px;
  font-size: 16px;
  font-family: Comfortaa, sans-serif;
  color: #1579A7;
  text-align: left;
}

.textFirstSectionMobile {
  color: #808080;
}

.subTitleFirstSection {
  font-family: Comfortaa, sans-serif;
  color: #1579A7;
  text-align: left;
}

.textFirstSection {
  color: #808080;
}

.firstSectionLeft {
  padding-left: 200px;
  padding-top: 100px;
}

.imgFirstSection {
  margin-top: -120px;
  margin-right: 200px;
}

.secondSection {
  color: #1579A7;
  background-color: #D5EEF4;
  padding: 50px 200px;
}

.secondSectionMobile {
  font-size: 14px;
  color: #1579A7;
  background-color: #D5EEF4;
  padding-top: 40px;
  padding-bottom: 40px;
}

.secondSectionLeft {
  padding-right: 150px;
}

.secondSectionRight {
  padding-left: 150px;
}

</style>
